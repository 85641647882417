import React from "react";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import PathwaysIntro from "../../components/pathways-intro";
import HeroPage from "../../components/hero-page";
import Pathways from "../../components/pathways";
import Seo from "../../components/seo";

const RealEstateFinanceInsurance = ({ data }) => {
  return (
    <>
      <Seo
        title="Real Estate, Finance and Insurance in North Central Pennsylvania"
        description="The Real Estate, Finance and Insurance (REFI) cluster contains all industries related to the purchase and sale of tangible and non-tangible assets"
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="work"
        caption=""
        position="45%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Real Estate, Finance and Insurance</h2>

            <p>
              The Real Estate, Finance and Insurance (REFI) cluster contains all
              industries related to the purchase and sale of tangible and
              non-tangible assets.
            </p>
          </div>

          <div className="intro-text" style={{ alignSelf: "center" }}>
            <p>
              Included in the cluster are consumer lending, commodity contracts
              brokerage, direct property & casualty insurers, residential
              property managers and credit bureaus.
            </p>
          </div>
        </section>

        <CareerLinkBox image={data.careerImage.sharp.image} caption="" />
      </main>

      <PathwaysIntro
        industry="Real Estate, Finance and Insurance"
        image={data.pathwaysImage.sharp.image}
      />

      <Pathways pathway={data.pathways.pathway} />
    </>
  );
};

export const data = graphql`
  query {
    pathways: pathwaysYaml {
      pathway: realestate {
        name
        associate {
          name
          occupations
          salary
          skills
        }
        diploma {
          name
          occupations
          salary
          skills
        }
        certificate {
          name
          occupations
          salary
          skills
        }
        bachelor {
          name
          occupations
          salary
          skills
        }
      }
    }
    careerImage: file(
      relativePath: { eq: "ronnie-george-Z2OkLCeLuCY-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    pathwaysImage: file(
      relativePath: { eq: "scott-graham-5fNmWej4tAA-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    heroImage: file(
      relativePath: { eq: "bailey-anselme-Bkp3gLygyeA-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "bailey-anselme-Bkp3gLygyeA-unsplash.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`;
export default RealEstateFinanceInsurance;
